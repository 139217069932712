import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeTemplate } from '@npt/npt-template';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public theme: ThemeTemplate = 'lightBlu_blu';
  constructor(
    private translateService: TranslateService
  ) { this.setI18n(); }

  private setI18n(): void {
    this.translateService.addLangs(['it', 'en']);
    this.translateService.setDefaultLang('it');
    this.translateService.use('it');
  }
}
