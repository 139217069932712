import { Injectable } from '@angular/core';
import { IMenuItemService, Menu } from '@npt/npt-template';

/* Roles allowed by the application */
export const ROLES = Object.freeze({
  DRIVER: 'driver',
  INSTALLER: 'installer',
  MOVYON: 'movyon',
  OPER_MOVYON: 'opmovyon',
  FLEETMNG: 'fleet'
});

const SUBMENU_ROUTES = [
  { state: 'assign-obu', name: 'Assign-obu', icon: 'plus', roles: [] },
  { state: 'remove-obu', name: 'Remove-obu', icon: 'delete', roles: [] },
  { state: 'change-obu', name: 'Change-obu', icon: 'refresh', roles: [] },
  { state: 'operation', name: 'Operation', icon: 'document_search', roles: [] },
];

const MENUITEMS = [
  {
    state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'home', children: [], roles: []
  },
  {
    state: 'manage-obu', name: 'Manage-obu', type: 'submenu', icon: 'obu',
    children: [SUBMENU_ROUTES[0], SUBMENU_ROUTES[1], SUBMENU_ROUTES[2], SUBMENU_ROUTES[3]],
    roles: []
  },
];

@Injectable()
export class MenuItemService implements IMenuItemService {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
