import { DashboardSetting } from "@npt/npt-template";
import { ROLES } from "src/app/npt-template-menu/menu-item.service";

export const dashboardSettings: DashboardSetting[] = [
  {
    role: ROLES.INSTALLER,
    cards: [
      {
        title: 'WELCOME.ASSIGN', content: '', icon: 'icon-obu', cardClass: 'mat-card-movyon-lightBlu',
        buttonClass: 'whiteButtons', routing: '../manage-obu/assign-obu'
      },
      {
        title: 'WELCOME.CHANGE', content: '', icon: 'icon-obu', cardClass: 'mat-card-movyon-lightBlu',
        buttonClass: 'whiteButtons', routing: '../manage-obu/change-obu'
      },
      {
        title: 'WELCOME.DELETE', content: '', icon: 'icon-obu', cardClass: 'mat-card-movyon-lightBlu',
        buttonClass: 'whiteButtons', routing: '../manage-obu/remove-obu'
      }
    ]
  }
];
